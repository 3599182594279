<template>
    <div class="middle">
        <div class="background">
            <img src="../../assets/images/bj_in_ma.png" width="100%" height="100%" alt=""/>
        </div>
        <div class="container padding-between-15px">

            <top-bar-main left-text="竞钓介绍" has-left-text/>

            <div class="game" v-if="fishPondList.length==0" style="text-align: center;font-size: 1.5rem">鱼塘暂未开放</div>
            <div v-else class="game" v-for="item in fishPondList" :key="item.id">
                <div class="game-title">
                    <img style="width: 2.5rem;height: 2.5rem" src="../../assets/images/yu.png" alt="">
                    <div>
                        <span>{{item.type==1?"1 天竞钓鱼塘":"7 天竞钓鱼塘"}}</span>
                        <span>{{item.name}}</span>
                    </div>
                </div>

                <div class="game-des">
                    {{item.type==1?dayInfo:weekInfo}}
                </div>

                <div class="game-bottom">
                    <div>
                        <div style="display: flex;align-items: center">
                            <span class="font-size-15">购买倒计时</span>
                            <!--                            <span style="font-size: 1.2rem;color: #E02020;margin-left: 1rem"> 23:23:24</span>-->
                            <count-down-time :end-time="item.endTime"></count-down-time>
                        </div>
                        <span class="font-size-18 font-weight-bold">您本期已购{{item.type==1?dayCount:weekCount}}竿</span>
                    </div>
                    <van-button type="info" color="#0073F8" style="width: 11rem;height: 4.3rem"
                                @click="showBuyFishDialog(item)">
                        购买鱼竿
                    </van-button>

                </div>

            </div>

        </div>

        <bottom-nav></bottom-nav>
        <van-popup v-model="show" position="bottom" style="height: 34rem" :lazy-render="false" :round="false">
            <div style="margin:2rem 1.5rem">
                <div class="flex-between">
                    <span class="font-size-15 font-weight-bold">购买鱼竿</span>
                    <img src="../../assets/images/close.png" style="width: 2rem;height: 2rem" alt=""
                         @click="show=false">
                </div>

                <div class="flex-between" style="height: 5rem;background-color: #FAFAF7;margin-top: 5rem">
                    <van-button class="add_and_subtract" @click="subtract">-</van-button>
                    <span class="number">{{num}}</span>
                    <van-button class="add_and_subtract" @click="add">+</van-button>
                </div>

                <div class="flex-between" style="margin-top: 2rem">
                    <span class="font-size-15">每竿价格</span>
                    <span class="font-size-15">{{sigle_price}}元</span>
                </div>
                <div class="flex-between" style="margin-top: 1.5rem">
                    <span class="font-size-15">总计价格</span>
                    <span class="font-size-15">{{total_price}}元</span>
                </div>

                <van-button type="info" size="large" style="margin-top: 3rem" @click="buyFishingRod">立即购买</van-button>
            </div>
        </van-popup>
    </div>
</template>

<script>
    import BottomNav from "../../components/BottomNav";
    import TopBarMain from "../../components/TopBarMain";

    import {Popup, Button, Toast} from "vant"
    import CountDownTime from "../../components/CountDownTime";

    export default {
        name: "Middle",
        data() {
            return {
                show: false,
                num: 1,
                sigle_price: 0,
                fishPondList: [],
                dayInfo: '',
                weekInfo: '',
                dayCount: 0,
                weekCount: 0,
                dayPrice: 0,
                weekPrice: 0,
                fishPondId: 0,
                status: 0,
                endTime: "2020-10-29 00:00:00",
            }
        },
        components: {
            CountDownTime,
            BottomNav,
            TopBarMain,
            [Popup.name]: Popup,
            [Button.name]: Button
        },
        activated() {
            this.fishPondHome();
        },
        methods: {
            fishPondHome() {
                this.$api.fishPond.fishPondHome({userId: this.$utils.getUserId()})
                    .then(res => {

                        let result = res.result;
                        this.fishPondList = result.fishPondList;
                        this.weekInfo = result.weekInfo;
                        this.dayInfo = result.dayInfo;
                        this.weekCount = result.weekCount;
                        this.dayCount = result.dayCount;
                        this.weekPrice = result.weekPrice;
                        this.dayPrice = result.dayPrice;
                        this.status = result.status;
                    }).catch(() => {

                });
            },
            buyFishingRod() {
                // let status= this.status;
                this.$api.fishPond.buyFishingRod({
                    userId: this.$utils.getUserId(),
                    count: this.num,
                    fishPondId: this.fishPondId
                }).then(res => {
                    this.show = false;
                    let json = {
                        orderId: res.result,
                        amount: "",
                        businessType: ""
                    }

                    this.$bridge.callNaiveApp("geek_storage_psr", json, res => {

                        if (res.code == 0) {
                            Toast.success(res.msg)
                            this.fishPondHome();
                        } else {
                            Toast.fail(res.msg)
                        }
                    })
                }).catch(() => {

                });
            },
            subtract() {
                if (this.num > 1)
                    this.num--;
            },
            add() {
                this.num++;
            },
            showBuyFishDialog(item) {
                this.show = true;
                this.num = 1;
                this.fishPondId = item.id;
                if (item.type == 1) {
                    this.sigle_price = this.dayPrice;
                } else {
                    this.sigle_price = this.weekPrice;
                }
            }
        },
        computed: {
            total_price: function () {
                return this.num * this.sigle_price;
            }
        }
    }
</script>

<style scoped>


    .container {
        display: flex;
        flex-direction: column;
        margin-bottom: 6.5rem;
        padding-top: 5rem;
    }

    .game {
        background: rgba(255, 255, 255, 0.8);
        display: flex;
        flex-direction: column;
        padding: 1.5rem;
        margin-top: 1rem;
        border-radius: 0.5rem;
    }

    .game-title {
        display: flex;
        align-items: center;

    }

    .game-title div {
        font-size: 1.8rem;
        font-weight: bold;
        flex-grow: 1;
        margin-left: 0.5rem;
        display: flex;
        justify-content: space-between;
    }

    .game-des {
        font-size: 1.5rem;
        margin-left: 3rem;
        margin-top: 1.5rem;
    }

    .game-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 2.5rem;

    }


    .add_and_subtract {
        height: 4.8rem;
        width: 4.8rem;
        background-color: #FAFAF7;
        font-size: 3rem;
        border: 0.1rem solid rgba(151, 151, 151, 0.29);
    }


    .number {
        flex-grow: 1;
        height: 100%;
        text-align: center;
        line-height: 5rem;
        font-size: 1.8rem;
    }


</style>
